.jenkins-file-upload {
  position: relative;
  width: 100%;
  margin: -10px 0 0 -10px;
  padding: 10px 0 10px 10px;
  outline: none;
  background: transparent;

  &::before {
    content: "";
    position: absolute;
    display: block;
    left: calc(10px + 0.9rem);
    width: 1rem;
    height: 36px;
    background: currentColor;
    mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M320 367.79h76c55 0 100-29.21 100-83.6s-53-81.47-96-83.6c-8.89-85.06-71-136.8-144-136.8-69 0-113.44 45.79-128 91.2-60 5.7-112 43.88-112 106.4s54 106.4 120 106.4h56' fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='40'/%3E%3Cpath fill='none' stroke='currentColor' stroke-linecap='round' stroke-linejoin='round' stroke-width='40' d='M320 255.79l-64-64-64 64M256 448.21V207.79'/%3E%3C/svg%3E");
    mask-position: center;
    mask-repeat: no-repeat;
    pointer-events: none;
  }

  &::file-selector-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: none;
    outline: none;
    margin: 0 1rem 0 0;
    padding: 0.5rem 0.85rem 0.5rem 2.5rem;

    // Firefox doesn't support pseudo elements on inputs so don't increase padding to accommodate
    @supports (-moz-appearance: none) {
      padding: 0.5rem 0.85rem;
    }

    font-size: 0.8rem;
    font-weight: normal;
    color: var(--text-color);
    border-radius: var(--form-input-border-radius);
    cursor: pointer;
    min-height: 36px;
    white-space: nowrap;
    background: var(--button-background);
    transition: var(--standard-transition);
    box-shadow: 0 0 0 10px transparent;

    &:hover {
      background: var(--button-background--hover);
    }

    &:active {
      background: var(--button-background--active);
      box-shadow: 0 0 0 5px var(--button-box-shadow--focus);
    }
  }

  &:focus-visible {
    &::file-selector-button {
      box-shadow: 0 0 0 0.2rem var(--text-color) !important;
    }
  }
}
