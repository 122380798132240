@use "../base/breakpoints";

.jenkins-search-container {
  position: relative;
}

.jenkins-search {
  --search-bar-height: 2.375rem;

  position: relative;

  &__input {
    padding: 0 0.25rem 0 calc(var(--search-bar-height) * 0.9);
    height: var(--search-bar-height);

    &::placeholder {
      color: var(--text-color-secondary);
    }

    // Safari adds unwanted padding - let's remove it
    &::-webkit-search-decoration {
      -webkit-appearance: none;
    }

    &::-webkit-search-cancel-button {
      -webkit-appearance: none;
      height: 45%;
      min-height: 1.1rem;
      aspect-ratio: 1;
      margin-right: 0.2rem;
      background: var(--text-color-secondary);
      mask-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath d='M256 48C141.31 48 48 141.31 48 256s93.31 208 208 208 208-93.31 208-208S370.69 48 256 48zm75.31 260.69a16 16 0 11-22.62 22.62L256 278.63l-52.69 52.68a16 16 0 01-22.62-22.62L233.37 256l-52.68-52.69a16 16 0 0122.62-22.62L256 233.37l52.69-52.68a16 16 0 0122.62 22.62L278.63 256z'/%3E%3C/svg%3E");
      mask-size: contain;
      mask-repeat: no-repeat;
      opacity: 0;
      pointer-events: none;
      transform: scale(0.8);
      transition: var(--standard-transition);
      cursor: pointer;

      &:hover {
        opacity: 0.85 !important;
      }

      &:active {
        opacity: 0.7 !important;
      }
    }

    &:not(:disabled) {
      &:active,
      &:focus {
        &::-webkit-search-cancel-button {
          opacity: 0.5;
          pointer-events: all;
          transform: scale(1);
        }
      }
    }

    &:active,
    &:focus {
      &::-webkit-search-cancel-button {
        opacity: 1;
        pointer-events: all;
        transform: scale(1);
      }
    }
  }

  &__icon {
    position: absolute;
    aspect-ratio: 1 / 1;
    top: 0;
    left: 0;
    bottom: 0;
    display: grid;
    pointer-events: none;

    svg {
      width: 1rem;
      height: 1rem;
      max-width: 1rem;
      max-height: 1rem;
      grid-column-start: 1;
      grid-row-start: 1;
      place-self: center center;
      transition: var(--standard-transition);
    }

    &::before,
    &::after {
      content: "";
      width: 45%;
      height: 45%;
      max-width: 1.1rem;
      max-height: 1.1rem;
      border: 0.125rem solid var(--text-color-secondary);
      border-radius: 100%;
      transition: var(--standard-transition);
      grid-column-start: 1;
      grid-row-start: 1;
      place-self: center center;
      opacity: 0;
      scale: 0;
      filter: blur(2.5px);
    }

    &::after {
      border-color: currentColor;
      clip-path: inset(0 0 50% 50%);
      animation: loading-spinner 1s infinite linear;

      @media (prefers-reduced-motion) {
        animation-duration: 2s;
      }
    }
  }

  .jenkins-keyboard-shortcut {
    position: absolute;
    top: calc(50% - 0.6875rem);
    right: 0.5rem;
    min-width: 1.375rem;
    min-height: 1.375rem;
    padding: 0;
    color: var(--text-color-secondary);
  }

  &--loading {
    .jenkins-search__icon {
      svg {
        opacity: 0;
        scale: 0;
        filter: blur(5px);
      }

      &::before {
        opacity: 0.5;
        scale: 1;
        filter: blur(0);
      }

      &::after {
        opacity: 1;
        scale: 1;
        filter: blur(0);
      }
    }
  }

  &--app-bar {
    --search-bar-height: 3rem;

    width: 100%;
    margin-block: -6px;

    @media (min-width: breakpoints.$tablet-breakpoint) {
      max-width: 50vw;
    }

    .jenkins-keyboard-shortcut {
      right: 0.8125rem;
    }

    &::before {
      content: "";
      position: absolute;
      inset: 0;
      border-radius: var(--form-input-border-radius);
      z-index: -1;
      backdrop-filter: blur(20px);
      box-shadow: 0 0 var(--section-padding) var(--background);

      @supports not (backdrop-filter: blur(20px)) {
        background: var(--background);
      }
    }
  }

  &:focus-within {
    .jenkins-search__icon {
      fill: var(--focus-input-border);
    }

    .jenkins-keyboard-shortcut {
      opacity: 0;
      transform: scale(0.9);
      pointer-events: none;
    }
  }

  &--disabled {
    color: var(--text-color-secondary);
    opacity: 0.5;

    .jenkins-keyboard-shortcut {
      display: none;
    }
  }
}

.jenkins-search__results-container {
  position: absolute;
  width: 100%;
  border-radius: 1rem;
  box-shadow: var(--dropdown-box-shadow);
  overflow: hidden;
  z-index: 10;
  height: 1px; // Setting to 0 caused the items not to render initially in Chrome
  opacity: 0;
  transition: var(--standard-transition);
  background: color-mix(in sRGB, var(--background) 85%, transparent);
  backdrop-filter: var(--dropdown-backdrop-filter);
  visibility: collapse;
  scale: 95%;
  translate: 0 -0.3125rem;
  will-change: height, scale, opacity;

  &--visible {
    opacity: 1;
    scale: 100%;
    visibility: visible;
    translate: 0 0.3125rem;
  }
}

.jenkins-search__results__no-results-label {
  text-align: center;
  margin: 2rem;
  padding: 0;
  color: var(--text-color-secondary);
  font-weight: var(--font-bold-weight);
}
